<template>
  <div
    class="modal modal-normal fade"
    id="modal-login"
    ref="modal-login"
    tabindex="-1"
    aria-labelledby="modal-login-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">
            <em class="material-icons">help</em>
            <span>PRIVATE LIVE</span>
          </div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons color="#C4C4C4" name="close-modal-login" />
          </div>
        </div>
        <div class="list-chat">
          <p class="title login">Anda harus terdaftar dalam event.</p>
          <p class="desc">Untuk menikmati fitur livestream, anda harus terdaftar dalam event {{data.eventName}}</p>
          <button type="button"
            @click="redirectEvent"
            class="btn btn-primary btn-xs"
            >LIHAT EVENT
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Icons.vue';
export default {
  components: {Icons},
  props: {
    data: null,
  },
  methods: {
    redirectEvent() {
      window.location = `/event/${this.data.eventOrganizerSlug}/${this.data.eventSlug}`
    }
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-login"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {

  .modal-dialog {
    bottom: 0;
  }

  .modal-content {
    background: white;
    height: fit-content;
    border-radius: 10px !important;
    width: 100vw;
    padding: 0 10px;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #E3E4E6;
      .title {
        display: flex;
        align-items: center;
        .material-icons {
          color: #F9E400;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          color: #424242;
        }
      }
      .close-modal {
        height: fit-content;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .list-chat {
      background: #fafafa;
      padding: 120px 50px;
      text-align: center;
      .btn {
        margin-top: 20px;
        padding: 5px 20px;
        border-radius: 4px;
      }
      svg {
        margin-bottom: 16px;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        color: #0c0b0d;
        margin-bottom: 8px;
        &.login {
          color: #4A25AA;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .desc {
        font-size: 12px;
        color: #616161;
        margin: 0;
      }
    }
}
</style>
