<template>
  <section
    class="livestreaming-section"
    v-if="$store.state.config.liveActive.items.length > 0"
  >
    <div
      class="live-banner container"
      v-if="$store.state.config.liveActive.items.length > 0"
      :style="`background-image: url('${$store.state.config.liveActive.items[0].banner}')`"
    >
      <div class="row">
        <div class="col live-copy-wrapper">
          <div class="live-icon-wrapper">
            <span class="live-icon"></span>
            <p class="live-text">LIVE</p>
          </div>
          <Link :to="`/live/${$store.state.config.liveActive.items[0].slug}`">
            <h1 class="live-title">
              {{ $store.state.config.liveActive.items[0].title }}
            </h1>
          </Link>
          <Link :to="`/live/${$store.state.config.liveActive.items[0].slug}`">
            <div class="live-action">
              <span>Tonton Sekarang</span>
              <i class="bi bi-arrow-right"></i>
            </div>
          </Link>
        </div>
        <div class="col live-iframe-wrapper">
          <client-only>
            <div class="player">
              <img
                v-if="
                  $store.state.config.liveActive.items[0].mediaIdPlayer
                    .length === 0 &&
                  !$store.state.config.liveActive.items[0].embedUrl
                "
                :src="$store.state.config.liveActive.items[0].thumbnail"
                class="img-fluid"
              />
              <div
                v-else
                class="ratio ratio-16x9"
                ref="videoPlayer"
                id="video-player"
              ></div>
            </div>
          </client-only>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as playerStreaming from "../../static/plugins/jwplayer-8.24.0/channel-embed";
import Link from "../Link.vue";

export default {
  components: { Link },
  props: {
    live: Object,
  },
  mounted() {
    this.$store
      .dispatch("live/getLiveStreaming", {
        pageLive: 1,
      })
      .finally(() => {
        if (this.$store.state.live.live.items.length > 0) {
          this.$store.commit("config/setStreaming", [
            this.$store.state.live.live.items[0],
          ]);

          const script = document.createElement("script");
          // script.onload = () => {
          //   let player = null;
          // };
          setTimeout(() => {
            if (
              this.$store.state.live.live.items[0].embedUrl &&
              !this.$store.state.live.live.items[0].channelIdPlayer
            ) {
              if (
                this.$store.state.live.live.items[0].embedUrl &&
                !this.$store.state.live.live.items[0].channelIdPlayer
              ) {
                new YT.Player("video-player", {
                  videoId: this.$store.state.live.live.items[0].embedUrl
                    ? this.$store.state.live.live.items[0].embedUrl.substr(
                        17,
                        11
                      )
                    : "",
                  height: 238,
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    modestbranding: 1,
                  },
                });
              } else {
                if (this.$store.state.live.live.items[0].status !== "idle") {
                  playerStreaming.setup({
                    jwplayer: jwplayer,
                    channelId:
                      this.$store.state.live.live.items[0].channelIdPlayer,
                    mediaId:
                      this.$store.state.live.live.items[0].mediaIdPlayer[0],
                    container: "video-player",
                    height: 238,
                    width: 424,
                  });
                } else {
                  let playlists = [];
                  this.$store.state.live.live.items[0].mediaIdPlayer.map(
                    (data, i) => {
                      playlists.push({
                        mediaid: data,
                        file: `https://cdn.jwplayer.com/manifests/${data}.m3u8`,
                      });
                    }
                  );

                  player = jwplayer("video-player").setup({
                    playlist: playlists.reverse(),
                    image: this.$store.state.live.live.items[0].thumbnail,
                    title: this.$store.state.live.live.items[0].title,
                    description:
                      this.$store.state.live.live.items[0].description,
                    aspectratio: "16:9",
                    stretching: "uniform",
                    width: "100%",
                    height: "473",
                    ga: {
                      gtag: "gtag",
                      label: "mediaid",
                      trackerName: "",
                      trackingobject: "_gaq",
                      universalga: "ga",
                    },
                  });
                }
              }
            }
          }, 50);

          script.type = "text/javascript";
          script.src = `https://cdn.jwplayer.com/libraries/${
            this.$store.state.live.live.items[0].status !== "idle"
              ? "GOujsiS6"
              : "LulCuIg8"
          }.js`;
          document.head.appendChild(script);
          const script2 = document.createElement("script");
          script2.src = `https://ssl.p.jwpcdn.com/live/channel-embed.js`;
          document.head.appendChild(script2);
        }
      });
  },
};
</script>

<style lang="scss">
.livestreaming-section {
  background-color: #0c0b0d;
}
.live-banner {
  height: 325px;
  padding: 40px 69px;
  background-size: cover;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1024px) {
    padding: 25px 10px;
    height: 190px;
  }
  .row {
    @media only screen and (max-width: 1024px) {
      align-items: center;
    }
    .live-copy-wrapper {
      max-width: 50%;
      @media only screen and (max-width: 1024px) {
        max-width: 40%;
      }
    }
    .live-iframe-wrapper {
      max-width: 50%;
      @media only screen and (max-width: 1024px) {
        max-width: 60%;
      }
    }
  }
  .live-icon-wrapper {
    background: #e73638;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px 10px;
    font-size: 14px;
    margin-bottom: 16px;
    border-radius: 5px;
    @media only screen and (max-width: 1024px) {
      padding: 4px 6px;
    }

    .live-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
      line-height: 0;
      margin-right: 5px;
      -webkit-animation: breathing 1s ease-out infinite normal;
      animation: breathing 1s ease-out infinite normal;
      @media only screen and (max-width: 1024px) {
        width: 7px;
        height: 7px;
      }
      @keyframes breathing {
        0% {
          -webkit-transform: scale(0.8);
          -ms-transform: scale(0.8);
          transform: scale(0.8);
        }

        25% {
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }

        60% {
          -webkit-transform: scale(0.8);
          -ms-transform: scale(0.8);
          transform: scale(0.8);
        }

        100% {
          -webkit-transform: scale(0.8);
          -ms-transform: scale(0.8);
          transform: scale(0.8);
        }
      }
    }

    .live-text {
      margin: 0;
      color: #fafafa;
      font-size: 14px;
      font-weight: 700;
      @media only screen and (max-width: 1024px) {
        font-size: 10px;
      }
    }
  }
  .live-action {
    display: flex;
    align-items: center;
    color: #fce600;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    @media only screen and (max-width: 1024px) {
      font-size: 10px;
    }
    span {
      margin-right: 10px;
      @media only screen and (max-width: 1024px) {
        margin-right: 5px;
      }
    }
    .bi {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .live-title {
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 1024px) {
      font-size: 13px;
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }
  }
  .player {
    width: 424px;
    height: 238px;
    border: 1px solid #ffffff;
    border-radius: 4px !important;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 108px;
    }
    iframe {
      border-radius: 4px !important;
      padding-bottom: 2px !important;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        height: 108px;
      }
    }
  }
}
</style>
