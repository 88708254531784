<template>
  <div class="modal modal-normal fade" id="modal-otp" ref="modal-otp" tabindex="-1" aria-labelledby="modal-otp-label'"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">Masukkan Kode Verifikasi</div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-otp" />
          </div>
        </div>
        <div class="desc">Kode verifikasi telah dikirim melalui email {{ email }}</div>
        <v-otp-input class="otp-input-wrapper" ref="otpInput" input-classes="otp-input" separator="" :num-inputs="4"
          :should-auto-focus="true" v-model="otp" :is-input-num="true" />
        <button class="btn btn-login btn-primary" @click="handleOnComplete">SUBMIT</button>
        <div class="register-wrapper">
          Tidak menerima kode?
          <countdown v-if="!allowResend" ref="countdownTimer" :startTime="$store.state.config.otp.startDate"
            :endTime="$store.state.config.otp.endDate" @finish="finishTime">
            <span class="resend-otp" slot="process" slot-scope="data">{{ `Tunggu ${data.timeObj.s} detik` }}</span>
          </countdown>
          <nuxt-link v-else to="" class="resend-otp" @click.native="resendOtp()">Kirim ulang</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import OtpInput from "@bachdgvn/vue-otp-input";
import config from "../../config";
import helper from "../../utils/helper";
import Icons from '../Icons.vue';

Vue.component("v-otp-input", OtpInput);

export default {
  components: {Icons},
  props: {
    event: null,
    redirect: null,
    email: null,
    password: null,
    // startDate: this.$moment().toISOString(),
    // endDate: this.$moment().add(300, 'seconds').toISOString()
  },
  data() {
    return {
      otp: '',
      allowResend: false
    }
  },
  methods: {
    resendOtp() {
      this.$store.commit('config/setOtpTime', {
        startDate: this.$moment().toISOString(),
        endDate: this.$moment().add(60, 'seconds').toISOString()
      })
    },
    finishTime(e) {
      this.allowResend = !this.allowResend;
    },
    async handleOnComplete() {
      if (this.$refs.otpInput.otp.length < 4) {
        return this.$toast.error('Kode harus 4 angka. Silakan coba lagi.')
      }
      const token = await this.$recaptcha.execute('verificationotp');
      this.$axios.post(`${config.USER_SERVICE_API}/api/auth/varification-otp`, {
        email: this.email ? this.email.toLowerCase() : this.email,
        otp: this.$refs.otpInput.otp.join().replaceAll(',', '')
      }, {
        headers: {
          recaptcha: token
        }
      }).then((response) => {
        if (
          !response.data.data.phoneNumber ||
          !response.data.data.email ||
          response.data.data.gender == null ||
          !response.data.data.city ||
          !response.data.data.birthdate ||
          !response.data.data.occupation ||
          !response.data.data.interests.length === 0 ||
          !response.data.data.privacy
        ) {
          this.$toast.info(
            "Anda harus melengkapi data anda terlebih dahulu."
          );
          helper.setCookie("narasi-temp", JSON.stringify(response.data), 1);
          if (this.$route.query.redirect) {
            window.location.href = this.$router.resolve(`/auth/complete-data?redirect=${this.$route.query.redirect}`).href;
          } else {
            window.location.href = this.$router.resolve(`/auth/complete-data`).href;
          }
        } else {

          if (process.client) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "LOGIN",
              authenticationMethod: "email",
              userId: response.data.data.id, //this should be replaced with an actual ID
            });
          }

          this.$auth.setUserToken(response.data.credential.accessToken).then(() => {
            this.$auth.setUser(response.data.data);
            this.$toast.success("Login menggunakan email berhasil.");
            if (this.$route.query.redirect) {
              window.location.href = this.$router.resolve(this.$route.query.redirect).href;
            } else {
              window.location.href = this.$router.resolve(this.$route.query.redirect).href;
            }
          })
        }


      })
        .catch((e) => {
          this.$toast.error('Kode verifikasi salah. Silakan coba lagi.')
        });
    }
  },
  async mounted() {

    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e);
    }

    this.$store.commit('config/setOtpTime', {
      startDate: this.$moment().toISOString(),
      endDate: this.$moment().add(60, 'seconds').toISOString()
    })
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-otp"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    background: white;
    height: fit-content;
    padding: 48px;
    border-radius: 10px !important;
    width: 400px;

    .btn-login {
      width: 100%;
      padding: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      color: #fafafa;
      margin-bottom: 10px;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: 500;
        color: #4A25AA;
        margin-bottom: 15px;

        @media only screen and (max-width: 1024px) {
          margin-bottom: 10px;
        }
      }

      .close-modal {
        height: fit-content;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #9e9e9e;
    }

    .otp-input-wrapper {
      width: 100%;
      justify-content: center;

      ::v-deep .otp-input {
        width: 51px;
        height: 64px;
        border-radius: 8px;
        border: 1px solid #9E9E9E;
        padding: 16px;
        margin: 24px 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #4A25AA;
        text-align: center;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 32px */
        letter-spacing: -0.64px;
      }
    }

    .resend-otp {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      text-decoration: none;
      color: #4A25AA;
    }

    .register-wrapper {
      padding-top: 8px;
      font-size: 12px;
      text-align: center;
      color: #9e9e9e;

      .btn-daftar {
        font-weight: 500;
        text-decoration: underline !important;

        &:hover {
          color: #4a25aa !important;
        }
      }
    }
  }
}
</style>
