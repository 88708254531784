<template>
  <div
    class="modal modal-normal fade"
    id="modal-share"
    ref="modal-share"
    tabindex="-1"
    aria-labelledby="modal-share-label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        <div class="modal-header">
          <h2 class="modal-title">Bagikan</h2>
          <div class="close" data-bs-dismiss="modal" aria-label="Close"></div>
        </div>
        <div class="modal-body">
          <a
            :href="setLink('whatsapp')"
            target="_blank"
            rel="noopener"
            class="sosmed-wrapper"
          >
            <Icons :color="iconsColor" name="logo-whatsapp" />
            <p class="sosmed">Whatsapp</p>
          </a>
          <a
            :href="setLink('twitter')"
            target="_blank"
            rel="noopener"
            class="sosmed-wrapper"
          >
            <Icons :color="iconsColor" name="logo-x" />
            <p class="sosmed">Twitter</p>
          </a>
          <a
            :href="setLink('facebook')"
            target="_blank"
            rel="noopener"
            class="sosmed-wrapper"
          >
            <Icons :color="iconsColor" name="logo-facebook" />
            <p class="sosmed">Facebook</p>
          </a>
          <div class="sosmed-wrapper" @click="copyLink()">
            <Icons :color="iconsColor" name="logo-copy-link" />
            <p class="sosmed">Copy Link</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Icons.vue';

export default {
  components: {Icons},
  props: {
    iconsColor: {
      type: String,
      default: "#4A25AA",
    },
  },
  data() {
    return {
      url: "",
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        this.url.includes("uid")
          ? this.url + "&utm_source=copy_link&utm_medium=share"
          : this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
    setLink(param) {
      if (this.url.includes("uid")) {
        switch (param) {
          case "whatsapp":
            return (
              "https://wa.me/?text=" +
              this.url +
              "%26utm_source=whatsapp%26utm_medium=share"
            );
          case "facebook":
            return (
              "https://www.facebook.com/sharer.php?u=" +
              this.url +
              "&utm_source=facebook&utm_medium=share"
            );
          case "twitter":
            return (
              "https://twitter.com/intent/tweet?url=" +
              this.url +
              "&utm_source=twitter&utm_medium=share"
            );
        }
      } else {
        switch (param) {
          case "whatsapp":
            return (
              "https://wa.me/?text=" +
              this.url +
              "%3Futm_source=whatsapp%26utm_medium=share"
            );
          case "facebook":
            return (
              "https://www.facebook.com/sharer.php?u=" +
              this.url +
              "?utm_source=facebook&utm_medium=share"
            );
          case "twitter":
            return (
              "https://twitter.com/intent/tweet?url=" +
              this.url +
              "?utm_source=twitter&utm_medium=share"
            );
        }
      }
    },
  },
  computed: {
    name() {
      return this.data;
    },
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-share"])
    );
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.modal {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.4))
    drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.6));
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    justify-content: center;
    .modal-content {
      border-radius: 10px;
      height: fit-content;
      width: 300px;
      @media only screen and (max-width: 1024px) {
        border-radius: 10px !important;
      }

      .modal-header {
        padding: 20px 30px;
        border: none;
        .modal-title {
          font-size: 20px;
          font-weight: 500;
          color: #051c2c;
        }
        .close {
          cursor: pointer;
          opacity: 1;
          width: 10px;
          height: 10px;
        }
      }
      .modal-body {
        display: flex;
        justify-content: space-between;
        padding: 0 30px 20px;
        .sosmed-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 0;
          cursor: pointer;
          svg {
            width: 35px;
            height: 35px;
            margin-bottom: 8px;
          }
          .sosmed {
            margin: 0;
            font-size: 12px;
            color: #828282;
          }
        }
      }
    }
  }
}
</style>
