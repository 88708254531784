var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal-voucher",staticClass:"modal modal-normal fade",attrs:{"id":"modal-voucher","tabindex":"-1","aria-labelledby":"modal-voucher-label'","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("Konfirmasi Kelasmu")]),_vm._v(" "),_c('div',{staticClass:"close-modal",attrs:{"data-bs-dismiss":"modal","aria-label":"Close"}},[_c('Icons',{attrs:{"name":"close-modal-login"}})],1)]),_vm._v(" "),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"input-group",class:_vm.$store.state.voucher.data.message &&
            _vm.$store.state.voucher.data.isValid
              ? 'border-valid'
              : _vm.$store.state.voucher.data.message
              ? 'border-invalid'
              : ''},[_vm._m(0),_vm._v(" "),_c('input',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.onSearch),expression:"onSearch",arg:"500ms"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukan kode voucher di sini"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}}),_vm._v(" "),(_vm.$store.state.voucher.data.isLoading)?_c('div',{staticClass:"input-group-prepend"},[_vm._m(1)]):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"voucher-status",class:_vm.$store.state.voucher.data.isValid
              ? 'voucher-valid'
              : 'voucher-invalid'},[_vm._v(_vm._s(_vm.$store.state.voucher.data.message))])]),_vm._v(" "),_vm._t("default")],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text non-loader",attrs:{"id":"basic-addon1"}},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-group-text loader",attrs:{"id":"basic-addon2"}},[_c('img',{attrs:{"src":"/loader.svg","width":"45","height":"45","alt":"Loader..."}})])
}]

export { render, staticRenderFns }