<template>
  <div class="live-chat">
    <ModalLogin @registeredModal="modalLogin = $event"/>
    <template v-if="+detail.liveChat === 0">
      <div class="list-chat">
        <Icons name="live-chat-closed"/>
        <p class="title">Fitur Livechat Tidak Aktif</p>
        <p class="desc">
          Fitur Livechat saat ini tidak Aktif.
        </p>
      </div>
    </template>
    <template v-else-if="+detail.liveChat === 1 && messages.length === 0">
      <div class="list-chat-empty">
        <div class="list-chat-empty-box">
          <Icons name="live-chat-closed"/>
          <p class="title">Belum Ada Chat di Livestream Ini</p>
          <p class="desc">
            Jadi yang pertama mengirimkan chat untuk bertukar gagasan dengan user lai
          </p>
        </div>
        <div class="d-flex justify-content-between align-items-center user-typing">
          <template v-if="$store.state.auth.loggedIn">
            <img v-if="$store.state.auth.user.picture" :src="$store.state.auth.user.picture" class="img-fluid rounded">
            <ProfilePictureChat
              v-else
              :initial="$store.state.auth.user.fullname.substr(0, 1)"
            />
          </template>
          <template v-else>
            <ProfilePictureChat initial=""
            />
          </template>
          <div class="input-merge" @click="isLogin">
            <form class="needs-validation w-100" ref="needsValidation" @submit.prevent="emitMessage" novalidate>
              <input type="text" v-model.trim="message" :maxlength="200" class="form-control" placeholder="Ketik sesuatu disini..">
            </form>
            <i class="material-icons" @click="emitMessage">send</i>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="+detail.liveChat === 1">
      <div class="list-chat-active">
        <div class="pinned" v-if="pinned">
          <div class="d-flex list-chatting-user-item justify-content-start">
            <img v-if="pinned.user.picture" :src="pinned.user.picture" class="img-fluid rounded">
            <ProfilePictureChat
              v-else
              :initial="pinned.user.fullname.substr(0, 1)"
            />
            <i class="fa bi-pin-fill"></i>
            <div class="user-chat">
              <span class="chat-description"><b>{{pinned.user.fullname}}</b>{{pinned.message}}</span>
            </div>
          </div>
        </div>
        <div class="list-chatting-user" ref="boxChatting">

          <div v-for="(item, idx) in messages" :key="`chat-${idx}`" class="d-flex list-chatting-user-item justify-content-start">
              <img v-if="item.user.picture" :src="item.user.picture" class="img-fluid rounded">
              <ProfilePictureChat
                v-else
                :initial="item.user.fullname.substr(0, 1)"
              />
            <div class="user-chat">
              <span class="chat-description"><b>{{item.user.fullname}}</b>{{item.message}}</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center user-typing">
          <img v-if="$store.state.auth.user.picture" :src="$store.state.auth.user.picture" class="img-fluid rounded">
          <ProfilePictureChat
            v-else
            :initial="$store.state.auth.user.fullname.substr(0, 1)"
          />
          <div class="input-merge" @click="isLogin">
            <form class="needs-validation w-100" ref="needsValidation" @submit.prevent="emitMessage" novalidate>
              <input type="text" v-model.trim="message" :maxlength="200" class="form-control" placeholder="Ketik sesuatu disini..">
            </form>
            <i class="material-icons" @click="emitMessage">send</i>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="+detail.liveChat === 2">
      <div class="list-chat">
        <Icons name="live-chat-closed"/>
        <p class="title">Fitur Livechat Belum Tersedia</p>
        <p class="desc">
          Anda bisa menggunakan fitur livechat setelah tersedia.
        </p>
      </div>
    </template>
    <template v-else-if="+detail.liveChat === 3">
      <div class="list-chat">
        <Icons name="live-chat-closed"/>
        <p class="title">Fitur Livechat Ditutup</p>
        <!-- <p class="desc">
          Anda bisa menggunakan fitur livechat di acara berikutnya ketika acara
          livestream dimulai.
        </p> -->
      </div>
    </template>
    <template v-else>
      <div class="list-chat">
        <p class="title login">SILAHKAN LOG IN.</p>
        <p class="desc">Untuk menikmati fitur livestream, anda harus log in terlebih dahulu.</p>
        <button type="button"
          @click="redirect(`/live/${$route.params.slug}`, 'login')"
          class="btn btn-primary btn-xs"
          >MASUK
        </button>
      </div>
    </template>
  </div>
</template>

<script>
  // import socket from '~/plugins/io/socket.io.js'
  import ProfilePictureChat from "../ProfilePictureChat";
  import Icons from "../Icons.vue";
  import ModalLogin from "../modal/Login.vue";

  export default {
    components: {ProfilePictureChat, Icons, ModalLogin},
    props: {
      referencyId: {
        type: String,
        default: null
      },
      detail: {
        type: Object,
        default: 1
      }
    },
    data() {
      return {
        message: '',
        modalLogin: null,
        messages: [],
        pinned: null
      }
    },
    methods: {
      redirect(url, type) {
        window.location = `/auth/${type}?redirect=${url}`
      },
      isLogin() {
        if (!this.$store.state.auth.loggedIn) {
          this.modalLogin.show();
        }
      },
      async emitMessage() {
        if (!this.message) { return }
        const that = this;
        const message = {
          user: this.$store.state.auth.user,
          slug: this.detail.slug,
          pinned: false,
          datetime: new Date().valueOf(),
          message: this.message.trim()
        };

        await this.$fire.firestore.collection('live-chats').add(message).then(async (data) => {
          that.scrollToBottom();
        }).catch((e) => {
          console.log(e);
        })
      },
      scrollToBottom() {
        if (this.$store.state.auth.loggedIn && this.messages.length > 0 && this.detail.liveChat === 1) {
          this.$nextTick(() => {
            try {
              this.$refs.boxChatting.scrollTop = this.$refs.boxChatting.scrollHeight
            } catch (error) {
              console.log(error);
            }
          })
        }
      }
    },
    mounted() {
      const that = this;
      this.$fire.firestore.collection('live-chats')
      .where('slug', '==', that.detail.slug)
      .orderBy('datetime', 'asc')
      .onSnapshot((snapshot) => {
        that.messages = [];
        this.message = ''
        snapshot.docs.map((item) => {
          if (item.data().pinned) {
            that.pinned = item.data();
          } else {
            that.messages.push(item.data())
          }
        });

        that.scrollToBottom();
      })
    }
  }
</script>

<style lang="scss" scoped>
  .live-chat {
    .list-chat-empty {
      background: #fafafa;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 420px;
      text-align: center;
      .list-chat-empty-box {
        padding: 0 50px;
        margin-top: 15%;
        align-items: center;
        svg {
          margin-bottom: 16px;
        }

        .title {
          font-size: 14px;
          font-weight: 500;
          color: #0c0b0d;
          margin-bottom: 8px;
          &.login {
            color: #4A25AA;
            font-weight: 700;
            font-size: 14px;
          }
        }

        .desc {
          font-size: 12px;
          color: #616161;
          margin: 0;
        }
      }
      .user-typing {
        padding: 10px;
        border-top: 1px solid #9E9E9E;
        height: 60px;

        .rounded {
          margin-right: 5px;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }

        .input-merge {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          .form-control {
            &:focus, &:active {
              border-bottom: 1px solid #4A25AA;
            }

            display: inline;
            background: transparent;
            width: 92%;
          }

          .material-icons {
            cursor: pointer;
            position: absolute;
            right: 0;
            color: #9E9E9E;
          }
        }

      }
    }
    .list-chat-active {
      background: #FAFAFA;
      position: relative;
      .pinned {
        /*position: absolute;*/
        color: #EEEEEE;
        padding: 10px;
        margin: 0 10px;
        width: 95%;
        background: #4A25AA;
        border-radius: 5px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        .list-chatting-user-item {
          margin-bottom: 8px;
          position: relative;

          .img-fluid.rounded {
            margin-right: 5px;
            width: 24px;
            height: 24px;
            object-fit: cover;
          }

          .bi-pin-fill {
            left: 10px;
            position: absolute;
            -ms-transform: rotate(45deg); /* Internet Explorer 9 */
            -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
            transform: rotate(45deg);
          }

          .user-chat {
            word-break: break-word;
            .chat-description {
              b {
                font-weight: 900;
                margin-right: 5px;
                font-size: 13px;
                color: #EEEEEE;
              }
            }
          }
        }
      }

      .list-chatting-user {
        padding: 10px;
        height: 360px;
        width: 100%;
        overflow-y: scroll;

        .list-chatting-user-item {
          margin-bottom: 8px;
          display: inline-block;
           .img-fluid.rounded {
            margin-right: 5px;
            width: 24px;
            height: 24px;
            object-fit: cover;
            border-radius: 50% !important;
          }
          .user-chat {
            word-break: break-word;
            .chat-description {
              b {
                font-weight: 900;
                margin-right: 5px;
                font-size: 13px;
                color: #5C6066;
              }
            }
          }
        }
      }

      .user-typing {
        padding: 10px;
        border-top: 1px solid #9E9E9E;
        height: 60px;

        .img-fluid.rounded {
          margin-right: 5px;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }

        .input-merge {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          .form-control {
            &:focus, &:active {
              border-bottom: 1px solid #4A25AA;
            }

            display: inline;
            background: transparent;
            width: 92%;
          }

          .material-icons {
            cursor: pointer;
            position: absolute;
            right: 0;
            color: #9E9E9E;
          }
        }

      }
    }

    .list-chat {
      background: #fafafa;
      padding: 120px 50px;
      text-align: center;
      .btn {
        margin-top: 20px;
        padding: 5px 20px;
        border-radius: 4px;
      }
      svg {
        margin-bottom: 16px;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        color: #0c0b0d;
        margin-bottom: 8px;
        &.login {
          color: #4A25AA;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .desc {
        font-size: 12px;
        color: #616161;
        margin: 0;
      }
    }
  }
</style>
