<template>
  <div
    class="modal modal-normal fade"
    id="modal-login"
    ref="modal-login"
    tabindex="-1"
    aria-labelledby="modal-login-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">
            Login terlebih dahulu <br />untuk melanjutkan, ya!
          </div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-login" />
          </div>
        </div>
        <div class="desc">
          Masuk atau buat akun Narasi untuk <br />melanjutkan perjalanan kamu di
          website
        </div>
        <Link :to="redirect ? `/auth/login?redirect=${redirect}` : `/auth/login`" class="btn-login">
          LOGIN
        </Link>
        <div class="register-wrapper">
          Belum punya akun Narasi?
          <Link
            :to="redirect ? `/auth/register?redirect=${redirect}` : `/auth/register`"
            class="btn-daftar"
          >
            Daftar Sekarang
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Link from "@/components/Link";
import Icons from "@/components/Icons";

export default {
  components: {
    Icons,
    Link
  },
  props: {
    event: null,
    redirect: null,
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-login"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    background: white;
    height: fit-content;
    padding: 20px 30px;
    border-radius: 10px !important;
    width: 300px;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #051c2c;
        margin-bottom: 15px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 10px;
        }
      }
      .close-modal {
        height: fit-content;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #9e9e9e;
      margin-bottom: 10px;
    }
    .btn-login {
      font-size: 14px;
      font-weight: 700;
      color: #fafafa;
      background: #4a25aa;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      margin-bottom: 10px;
      border-radius: 6px;
      &:hover {
        color: white !important;
      }
    }
    .register-wrapper {
      font-size: 12px;
      text-align: center;
      color: #9e9e9e;
      .btn-daftar {
        font-weight: 500;
        text-decoration: underline !important;
        &:hover {
          color: #4a25aa !important;
        }
      }
    }
  }
}
</style>
