<template>
  <div
    class="modal modal-normal fade"
    id="modal-component"
    ref="modal-component"
    tabindex="-1"
    aria-labelledby="modal-component-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">
            {{ desc }}
          </div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-login" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Icons.vue';
export default {
  components: {Icons},
  props: {
    event: null,
    desc: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-component"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    background: white;
    height: fit-content;
    padding: 20px 30px;
    border-radius: 10px !important;
    width: 500px;
    @media only screen and (max-width: 1024px) {
      width: 300px;
    }
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 18px;
        font-weight: 400;
        color: #9e9e9e;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
      .close-modal {
        height: fit-content;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
