<template>
  <div
    class="modal modal-normal fade"
    id="modal-report-comment"
    ref="modal-report-comment"
    tabindex="-1"
    aria-labelledby="modal-report-comment-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Laporkan Komentar</h2>
          <div class="close" data-bs-dismiss="modal" aria-label="Close"></div>
        </div>
        <div class="modal-body">
          <div class="modal-body__desc">
            Beri tahu kami mengapa Anda ingin melaporkan komentar ini:
          </div>
          <div class="modal-body__radio-wrapper">
            <div class="form-check radio">
              <input
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                :value="1"
                v-model="type"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Komentar ini adalah spam
              </label>
            </div>
            <div class="form-check radio">
              <input
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                :value="2"
                v-model="type"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Bersifat menyerang atau mengancam
              </label>
            </div>
            <div class="form-check radio">
              <input
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                :value="3"
                v-model="type"
              />
              <label class="form-check-label" for="flexRadioDefault3">
                Lainnya
              </label>
            </div>
          </div>
          <div class="modal-body__button">
            <span @click="reportComment()"
              >LAPORKAN</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      type: null,
    };
  },
  methods: {
    reportComment() {
      let data = {
        commentId: this.commentId,
        type: this.type,
      };
      this.$emit("event", data);
    },
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-report-comment"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {
  // filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.4))
  //   drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.6));
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    border-radius: 10px !important;
    width: 300px;
    .modal-header {
      padding: 20px 30px;
      border: none;
      .modal-title {
        font-size: 20px;
        font-weight: 500;
        color: #4a25aa;
      }
      .close {
        cursor: pointer;
        opacity: 1;
        width: 5px;
        height: 5px;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 20px;
      &__desc {
        font-size: 12px;
        font-weight: 400;
        color: #828282;
        margin-bottom: 10px;
      }
      &__radio-wrapper {
        .radio {
          display: flex;
          align-items: center;
          padding: 0;
          margin-bottom: 8px;
          input {
            margin-right: 10px;
          }
          label {
            font-size: 12px;
            color: #333333;
          }
          &:last-child {
            padding: 0;
            margin-bottom: 16px;
          }
        }
      }
      &__button {
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          color: white;
          background: #4a25aa;
          display: block;
          text-align: center;
          padding: 10px;
          border-radius: 5px;
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
}
</style>
