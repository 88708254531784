<template>
  <div
    class="modal modal-normal fade"
    id="modal-voucher"
    ref="modal-voucher"
    tabindex="-1"
    aria-labelledby="modal-voucher-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">Konfirmasi Kelasmu</div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons name="close-modal-login" />
          </div>
        </div>
        <div class="desc">
          <div
            class="input-group"
            :class="
              $store.state.voucher.data.message &&
              $store.state.voucher.data.isValid
                ? 'border-valid'
                : $store.state.voucher.data.message
                ? 'border-invalid'
                : ''
            "
          >
            <div class="input-group-prepend">
              <span class="input-group-text non-loader" id="basic-addon1"
                >%</span
              >
            </div>
            <input
              type="text"
              v-debounce:500ms="onSearch"
              :value="value"
              v-on:input="updateValue($event.target.value)"
              class="form-control"
              placeholder="Masukan kode voucher di sini"
            />
            <div
              v-if="$store.state.voucher.data.isLoading"
              class="input-group-prepend"
            >
              <span class="input-group-text loader" id="basic-addon2"
                ><img src="/loader.svg" width="45" height="45" alt="Loader..."
              /></span>
            </div>
          </div>
          <span
            class="voucher-status"
            :class="
              $store.state.voucher.data.isValid
                ? 'voucher-valid'
                : 'voucher-invalid'
            "
            >{{ $store.state.voucher.data.message }}</span
          >
        </div>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Icons.vue';

export default {
  components: {Icons},
  props: {
    event: null,
    service: {
      type: String,
      default: "",
    },
    refId: {
      type: Number,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onSearch(e) {
      this.$store.dispatch("voucher/checkVoucher", {
        voucher: this.value,
        forService: this.service,
        refId: this.refId,
      });
    },
    updateValue(value) {
      this.$emit("input", value.toUpperCase());
    },
    redirect(url, type) {
      if (this.event) {
        this.event.hide();
      }
      window.location.href = this.$router.resolve(`/auth/${type}?redirect=${url}`).href
    },
  },
  afterMounted() {
    this.isLoading = false;
    this.isValid = false;
    this.message = null;
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-voucher"])
    );
    this.$store.dispatch("voucher/checkVoucher", {
      voucher: this.value,
      forService: this.service,
      refId: this.refId,
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
  }
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    background: white;
    height: fit-content;
    padding: 20px 24px;
    border-radius: 4px !important;
    width: 407px;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #051c2c;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 10px;
        }
      }
      .close-modal {
        height: fit-content;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #9e9e9e;
      margin-bottom: 10px;
      margin-top: 10px;
      .voucher-valid {
        margin-top: 3px;
        color: #43936c;
        font-size: 14px;
        font-weight: 400;
      }
      .voucher-invalid {
        margin-top: 3px;
        color: #ff0000;
        font-size: 14px;
        font-weight: 400;
      }
      .input-group {
        margin-bottom: 10px;
        background-color: #f1f1f1;
        border-radius: 4px;
        border: 1px solid #9e9e9e;
        position: relative;
        &.border-invalid {
          border: 1px solid #ff0000;
        }
        &.border-valid {
          border: 1px solid #43936c;
        }
        .form-control {
          background-color: #f1f1f1 !important;
          color: #616161 !important;
          font-size: 14px;
          border: unset !important;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          height: 45px;
          &::placeholder {
            color: #9e9e9e;
            opacity: 1;
          }
          &:focus {
            box-shadow: unset !important;
          }
        }
        .input-group-text {
          background-color: #f1f1f1 !important;
          color: #9e9e9e !important;
          font-size: 18px;
          border: unset !important;
          height: 45px;
          text-align: center;
          font-weight: 900;
          &.non-loader {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &.loader {
            padding: unset !important;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
    .btn-voucher {
      font-size: 14px;
      font-weight: 700;
      color: #fafafa;
      background: #4a25aa;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      margin-bottom: 10px;
      border-radius: 6px;
      &:hover {
        color: white !important;
      }
    }
    .register-wrapper {
      font-size: 12px;
      text-align: center;
      color: #9e9e9e;
      .btn-daftar {
        font-weight: 500;
        text-decoration: underline !important;
        &:hover {
          color: #4a25aa !important;
        }
      }
    }
  }
}
</style>
