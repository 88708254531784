<template>
  <div class="modal-instagram" v-show="show" @click="hide()">
    <iframe
      :src="`/suarapenentu/iframe?reel_id=${reel_id}`"
      v-if="show"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    reel_id: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      this.show = false;
      this.$emit("hide-modal-ig");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-instagram {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-bottom: 5vw;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: 1024px) {
    padding: 50px 20px;
  }
  iframe {
    width: 21vw;
    height: 35vw;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 70vh;
    }
  }
}
</style>
