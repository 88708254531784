<template>
  <div
    class="modal modal-normal fade"
    id="modal-login"
    ref="modal-login"
    tabindex="-1"
    aria-labelledby="modal-login-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="title-wrapper">
          <div class="title">
            <em class="material-icons">question_answer</em>
            <span>LIVE CHAT</span>
          </div>
          <div class="close-modal" data-bs-dismiss="modal" aria-label="Close">
            <Icons color="#C4C4C4" name="close-modal-login" />
          </div>
        </div>
        <LiveChatting :detail="data"/>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '../Icons.vue';
import LiveChatting from '~/components/live/Chatting.vue';
export default {
  components: {Icons, LiveChatting},
  props: {
    data: null
  },
  mounted() {
    this.$emit(
      "registeredModal",
      this.getBootstrapModal(this.$refs["modal-login"])
    );
  },
};
</script>

<style lang="scss" scoped>
.modal {

  .modal-dialog {
    bottom: 0;
  }

  .modal-content {
    background: white;
    height: fit-content;
    border-radius: 10px !important;
    width: 100vw;
    padding: 0 10px;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #E3E4E6;
      .title {
        display: flex;
        align-items: center;
        .material-icons {
          color: #F9E400;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          color: #424242;
        }
      }
      .close-modal {
        height: fit-content;
        cursor: pointer;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>
