<template>
  <div class="position-relative">
    <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper">
        <ImageResponsiveBanner
          class="swiper-slide"
          v-for="(item, index) in data"
          :key="index"
          :imageUrl="
            item.banner && item.banner.desktop
              ? item.banner.desktop
              : 'https://narasi.tv/storage/images/dummy-banner.png'
          "
          :imageUrlMobile="
            item.banner && item.banner.mobile
              ? item.banner.mobile
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :fromUrl="true"
          :width="width ? width : ''"
          :height="height ? height : ''"
          alt=""
        />
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <Icons name="arrow-left" class="arrow nav-left" />
    <Icons name="arrow-right" class="arrow nav-right" />
  </div>
</template>

<script>
import Icons from "/components/Icons";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "swiper-example-navigation",
  title: "Navigation",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
  },
  components: {
    Icons,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".nav-right",
          prevEl: ".nav-left",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  img {
    width: 100%;
    height: auto;
  }
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  &.nav-left {
    left: 0;
  }
  &.nav-right {
    right: 0;
  }
}
</style>
