<template>
  <transition name="modal">
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="modal-image">
        <ImageResponsive
          :imageUrl="imageData"
          :fromUrl="true"
          alt="image-thumbnail"
        />
        <div class="close-modal" @click="$emit('close-modal')">
          <Icons name="close-modal" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";

export default {
  components: {
    ImageResponsive,
    Icons,
  },
  props: {
    imageData: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000da;
  z-index: 999;
  .modal-image {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: auto;
    @media only screen and (max-width: 1024px) {
      width: 90%;
    }
    img {
      width: 100%;
    }
  }
  .close-modal {
    position: absolute;
    top: 2%;
    right: 1%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      width: 20px;
      height: 20px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
