<template>
  <div class="live-chat">
    <ModalLogin @registeredModal="modalLogin = $event" />
    <template v-if="+detail.polling === 0">
      <div class="list-chat">
        <Icons name="live-chat-closed" />
        <p class="title">Fitur Polling Tidak Aktif</p>
        <p class="desc">
          Untuk saat ini belum tersedia polling yang dapat anda akses.
        </p>
      </div>
    </template>
    <template v-else-if="+detail.polling === 1">
      <div v-if="polling" class="list-polling">
        <span class="polling-title">{{ polling.question }}</span>
        <ul class="list-unstyled">
          <li
            v-for="(item, idx) in polling.answers"
            :style="
              isPolling ? `border: unset;` : `border: 0.5px solid #0C0B0D;`
            "
            :key="`question-${idx}`"
            @click="vote(idx, item)"
          >
            <span
              :style="
                isPolling
                  ? `width: ${(
                      (item.pollings.length / totalAnswer) *
                      100
                    ).toFixed()}%; height: 25px; background-color: #FFE900; border-radius: 5px;`
                  : `height: 25px;`
              "
            >
              <div class="question">{{ item.option }}</div>
            </span>
            <span v-if="isPolling" class="percentage"
              >{{
                ((item.pollings.length / totalAnswer) * 100).toFixed()
              }}%</span
            >
          </li>
          <span v-if="isPolling" class="totalVote">{{ totalAnswer }} vote</span>
        </ul>
      </div>
    </template>
    <template v-else-if="detail.polling === 2">
      <div class="list-chat">
        <Icons name="live-chat-closed" />
        <p class="title">Polling Belum Dibuka</p>
        <p class="desc">
          Untuk saat ini belum tersedia polling yang dapat anda akses.
        </p>
      </div>
    </template>
    <template v-else-if="+detail.polling === 3">
      <div class="list-chat">
        <Icons name="live-chat-closed" />
        <p class="title">Fitur Polling Ditutup</p>
        <!-- <p class="desc">Anda bisa menggunakan fitur polling di acara berikutnya ketika acara
          livestream dimulai.</p> -->
      </div>
    </template>
    <template v-else>
      <div class="list-chat">
        <p class="title login">SILAHKAN LOG IN.</p>
        <p class="desc">
          Untuk menikmati fitur livestream, anda harus log in terlebih dahulu.
        </p>
        <button
          type="button"
          @click="redirect($route.fullPath, 'login')"
          class="btn btn-primary btn-xs"
        >
          MASUK
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Icons from "../Icons.vue";
import ModalLogin from "../modal/Login.vue";

export default {
  components: {
    Icons,
    ModalLogin,
  },
  props: {
    referencyId: {
      type: String,
      default: null,
    },
    detail: {
      type: Object,
      default: 1,
    },
  },
  data() {
    return {
      idPolling: null,
      isPolling: false,
      polling: null,
      totalAnswer: 0,
    };
  },
  methods: {
    redirect(url, type) {
      window.location = `/auth/${type}?redirect=${url}`;
    },
    async vote(idx, question) {
      if (!this.isPolling) {
        const user = this.$store.state.auth.user;
        let copyPolling = this.polling.answers;

        copyPolling[idx].pollings.push({
          id: user.id,
          email: user.email,
          fullname: user.fullname,
        });

        copyPolling.map((answers) => {
          if (
            answers.pollings.filter(
              (v) => v.id === this.$store.state.auth.user.id
            ).length > 0
          ) {
            this.isPolling = true;
          }
        });

        await this.$fire.firestore
          .collection("pooling")
          .doc(this.idPolling)
          .update({
            answers: copyPolling,
          });
      } else {
        this.$toast.error("Anda sudah pernah memberikan vote...");
      }
    },
  },
  mounted() {
    if (this.detail.polling != 0) {
      const that = this;
      this.$fire.firestore
        .collection("pooling")
        .where("slug", "==", that.detail.slug)
        .onSnapshot((snapshot) => {
          if (snapshot.docs.length > 0) {
            that.idPolling = snapshot.docs[0].id;
            this.polling = snapshot.docs[0].data();
            this.polling.answers.map((answers) => {
              this.totalAnswer += answers.pollings.length;
              if (
                this.$store.state.auth.loggedIn &&
                answers.pollings.filter(
                  (v) => v.id === this.$store.state.auth.user.id
                ).length > 0
              ) {
                this.isPolling = true;
              }
            });
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.list-polling {
  // background: #fafafa;
  padding: 10px 20px;
  // min-height: 420px;
  .polling-title {
    font-size: 14px;
    color: #0c0b0d;
  }
  .list-unstyled {
    margin-top: 10px;
    .totalVote {
      font-weight: 500;
      color: #4a25aa;
      font-size: 12px;
    }
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border: 0.5px solid #0c0b0d;
      margin-bottom: 5px;
      padding: 5px 0;
      border-radius: 5px;
      .percentage {
        color: #4a25aa;
        font-size: 12px;
        margin-left: 10px;
      }
      .question {
        position: absolute;
        padding: 5px 5px;
      }
    }
  }
}
.list-chat {
  background: #fafafa;
  padding: 120px 50px;
  text-align: center;
  .btn {
    margin-top: 20px;
    padding: 5px 20px;
    border-radius: 4px;
  }
  svg {
    margin-bottom: 16px;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #0c0b0d;
    margin-bottom: 8px;
    &.login {
      color: #4a25aa;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .desc {
    font-size: 12px;
    color: #616161;
    margin: 0;
  }
}
</style>
